<template>
  <div class="singin">
    <div class="navbar" @click="navbarClick">
      <van-icon name="down" color="rgba(0, 0, 0, 0.85)" size="24" />
    </div>
    <div class="title">
      <p>{{ $t("singin.title1") }}<br />{{ $t("singin.title2") }}</p>
    </div>
    <van-form @submit="onSubmit">
      <van-cell-group>
        <span class="field_label">{{ $t("singin.phone") }}</span>
        <van-field
          v-model="phone"
          name="phone"
          :placeholder="$t('singin.phone_warn')"
          :rules="[{ required: true, message: $t('singin.phone_warn') }]"
        />
      </van-cell-group>
      <van-cell-group>
        <span class="field_label">{{ $t("singin.name") }}</span>
        <van-field
          v-model="realName"
          name="realName"
          :placeholder="$t('singin.name_warn')"
          :rules="[{ required: true, message: $t('singin.name_warn') }]"
        />
      </van-cell-group>
      <van-cell-group>
        <span class="field_label">{{ $t("singin.idCard") }}</span>
        <van-field
          v-model="idCard"
          name="idCard"
          :placeholder="$t('singin.idCard_warn')"
          :rules="[{ required: true, message: $t('singin.idCard_warn') }]"
        />
      </van-cell-group>
      <van-cell-group>
        <span class="field_label">{{ $t("singin.bankNo") }}</span>
        <van-field
          v-model="bankCardNo"
          name="bankCardNo"
          :placeholder="$t('singin.bankNo_warn')"
          :rules="[{ required: true, message: $t('singin.bankNo_warn') }]"
        />
      </van-cell-group>
      <van-cell-group @click="bankClick">
        <span class="field_label">{{ $t("singin.bankName_warn") }}</span>
        <van-field
          v-model="bankName"
          name="bankName"
          :placeholder="$t('singin.bankName_warn')"
          right-icon="arrow-up"
          disabled
          :rules="[{ required: true, message: $t('singin.bankName_warn') }]"
        />
      </van-cell-group>
      <div style="margin-top: 32px;">
        <van-button round block type="primary" native-type="submit">{{
          $t("singin.next")
        }}</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { clerkAuth } from "@/network/login";
export default {
  name: "singin",
  data() {
    return {
      // realName: null,
      // phone: null,
      // idCard: null,
      // bankCardNo: null,
      phone: "",
      realName: "",
      idCard: "",
      bankCardNo: "",
      bankName: null,
      bankId: null,
      type: "",
    };
  },
  created() {
    if (this.$route.query.bankName) {
      this.bankName = this.$route.query.bankName;
      this.bankId = Number(this.$route.query.id);
    }
    let data = JSON.parse(window.sessionStorage.getItem("singInfo"));
    if (data) {
      this.phone = data.phone;
      this.realName = data.realName;
      this.idCard = data.idCard;
      this.bankCardNo = data.bankCardNo;
    }
  },
  methods: {
    navbarClick(){
      window.sessionStorage.removeItem('singInfo')
      this.$router.push('/login')
    },
    // 存储填的信息
    setLoscl() {
      let singInfo = {
        phone: this.phone,
        realName: this.realName,
        idCard: this.idCard,
        bankCardNo: this.bankCardNo,
      };
      window.sessionStorage.setItem("singInfo", JSON.stringify(singInfo));
    },
    bankClick() {
      this.setLoscl();
      // N表示是没登录的，要进行注册验证页面跳转的
      this.$router.push({
        path: "/bankNameList",
        query: {
          type: "N",
        },
      });
    },
    onSubmit(values) {
      values.bankId = this.bankId;
      let data = {
        name: values.realName,
        idCardNo: values.idCard,
        phone: values.phone,
        bankNo: values.bankCardNo,
        type: "REGISTER_AUTH",
      };
      clerkAuth(data)
        .then((res) => {
          if (res.code === "000000") {
            this.setLoscl();
            this.$router.push({
              path: "/setPassword",
              query: {
                data: values,
                bankName: this.bankName,
                id: this.bankId
              },
            });
          } else {
            this.$dialog
              .alert({
                message: res.message,
                confirmButtonText:this.$t('stringCode.Done'),
              })
              .then(() => {
                // on close
              });
          }
        })
        .catch((err) => {
          this.$dialog
            .alert({
              message: err.message,
              confirmButtonText:this.$t('stringCode.Done'),
            })
            .then(() => {
              // on close
            });
        });
    },
  },
};
</script>

<style scoped>
.navbar {
  padding-top: 25px;
}
.singin /deep/ .van-icon {
  transform: rotate(90deg);
}
.singin {
  padding: 0 25px;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
}
.title {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #333333;
}
.singin /deep/ .van-field__control::placeholder {
  color: #ccc;
}
.singin /deep/ .van-cell-group {
  background-color: #fafafa;
  margin-bottom: 16px;
}
.singin /deep/ .van-hairline--top-bottom::after {
  border-width: 0 0 1px;
}
.singin /deep/ .van-cell {
  padding: 8px 0;
  background-color: #fafafa;
}
.field_label {
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.55);
}
</style>
